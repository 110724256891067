.NavigationBar{
    width: 100%;
    background: black;
    opacity: 0.75;
    color: white;
    position: fixed;
    top: 0;
    text-align: left;
}

a {
    color: white;
}