@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&family=Rubik:ital,wght@1,500&display=swap');

@font-face {
    font-family: MyRubik;
    src: url(Rubik-MediumItalic.ttf);
}

.Hero {
    display: block;
    /* background: rgb(67, 62, 156) !important;
    background: linear-gradient(220deg, rgba(77, 77, 243, 1) 30%, rgba(216, 196, 45, 1) 50%, rgba(77, 77, 243, 1) 70%) !important; */
    height: 100%;
    width: 100%;
    padding: 20% 20%;
    color: white;
};


.arms {
    width: 100% !important;
    height: 100% !important;
}

/* .imgBox{
    max-width: 400px !important;
    min-width: 100px !important;
} */

.caret{
    width: 15% !important;
    height: 15% !important;
    /* align-items: center !important; 
    justify-content: center !important; */
    animation: jumpInfinite 1.5s infinite;
}

h1{
    font-family: 'Apple Chancery', sans-serif;
    font-size: 3rem !important;
}

p{
    font-family: 'MyRubik', sans-serif; /*Not working on mobile!! Also, should I put a big spotify logo under the search bar? */
}

@keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 15px;
      margin-bottom: -15px;
    }
    100% {
      margin-top: 0;
    }
  }

.welcome-mobile{
    display: none;
}

.frat-name-mobile{
    display: none;
}

/* .built-by{
    display: inline-block;
    font-family: 
}

.heart{
    width: 18px !important;
    height: 18px !important;
} */


@media only screen and (max-device-width: 480px) {
    .welcome-mobile{
        font-size: 2.6rem !important;
        margin-bottom: -3px !important;
        margin-top: -15px !important;
        padding-bottom: 0px !important;
        display: block !important;
    }
    .frat-name-mobile{
        margin-top: 0px !important;
        padding-top: 0px !important;
        font-size: 3.8rem !important;
        padding-bottom: 130px !important;
        display: block !important;
    }
    p{
        padding-bottom: 0px !important;
        margin-bottom: -3px !important;
    }
    .frat-name{
        display: none;
    }
}

@media only screen and (max-device-width: 480px) and (min-device-width:390px){
    .Hero{
        padding-top: 10% !important;
        padding-bottom: 0% !important;
    }
    .frat-name-mobile{
        padding-bottom: 130px !important;
    }
    .scroll-down{
        margin-top: -85px !important;
        margin-bottom: 85px !important;
    }
}

