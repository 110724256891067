/* .dj-button:not(:disabled):not(:disabled):active {
    background-color: #1db954 !important;
    border-color: #1db954;
    box-shadow: 0 0 0 0.2rem rgba(29, 185, 84, 0.5);
} */
@font-face {
  font-family: MyRubik;
  src: url(Rubik-Light.ttf);
}
@font-face {
  font-family: MyRubik2;
  src: url(Rubik-Regular.ttf);
}

.form-control { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: MyRubik !important;
  font-size: 1rem !important;
}

.search-btn{
  font-family: MyRubik2 !important;
  font-size: 1rem !important; /*bigger without this, but expands whole box*/
}

.request-btn{
  font-family: MyRubik2 !important;
}

.btn-spotify {
    background-color: #1ed660 !important;
    border-color: #1ed660 !important; /*all were #1db954 previously*/

    --bs-btn-color: #fff;
    --bs-btn-bg: #1ed660;
    --bs-btn-border-color: #1ed660;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #1ed660;
    --bs-btn-hover-border-color: #1ed660;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1ed660;
    --bs-btn-active-border-color: #1ed660;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #1ed660;
    --bs-btn-disabled-border-color: #1ed660;
  }

  .inputbox {
    margin-top: 1.5rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

